<template>
    <article class="member">
        <figure
            v-if="$slots.picture"
            class="member__picture"
        >
            <slot name="picture" />
        </figure>

        <div class="member__info">
            <slot />
        </div>
    </article>
</template>

<style lang="less">
.member {
    .typography-text();
    display: inline-flex;
    align-items: flex-start;
    border-bottom: 1px solid var(--color-gray-200);
    padding-bottom: 1.5rem;
}

.member__info {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: space-between;

    strong {
        .typography-label-secondary();
    }
}

.member__picture {
    width: 5rem;
    height: auto;
    overflow: hidden;
    border-radius: var(--borderRadius-xs);
    margin-right: var(--spacing-lg);

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @media @q-lg-min {
        margin-right: var(--spacing-xl);
    }
}

.member header + .vue-content-placeholders-text {
    margin-top: 1em;
}
</style>
